import clsx, { type ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const customTwMerge = extendTailwindMerge({});

const cn = (...args: ClassValue[]) => {
  return customTwMerge(clsx(...args));
};

export default cn;
