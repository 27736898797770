import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

export const GenericStyles = cva("", {
  variants: {
    padding: {
      sm: "p-2",
      md: "p-4",
      lg: "p-8",
      true: "p-4",
    },
    paddingX: {
      true: "px-4",
    },
    paddingY: {
      true: "py-4",
    },
    border: {
      true: "border",
    },
    bg: {
      accent: "bg-accent",
    },
  },
});

export type GenericStylesProps = VariantProps<typeof GenericStyles>;
